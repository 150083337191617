import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Button order</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Button alignment</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Labels</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Icon usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Danger button usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><strong parentName="p">{`Buttons are used to initialize an action.`}</strong>{` Button labels express what action will occur when the user interacts with it.`}</p>
    <p><strong parentName="p">{`Buttons are used primarily for actions.`}</strong>{` Examples include Add, Save, Delete, and Sign up. Each page should have one primary button, and a `}<strong parentName="p">{`Return`}</strong>{` or `}<strong parentName="p">{`Enter`}</strong>{` keyboard event should trigger it. Any remaining calls-to-action should be represented as secondary buttons.`}</p>
    <p><strong parentName="p">{`Do not use buttons as navigational elements.`}</strong>{` Instead, use `}<a parentName="p" {...{
        "href": "/components/link"
      }}>{`links`}</a>{` when the desired action is to take the user to a new page.`}</p>
    <p><strong parentName="p">{`Primary buttons always appear to the right.`}</strong>{` Secondary buttons appear to the left of the primary button.`}</p>
    <h2 {...{
      "id": "button-order"
    }}>{`Button order`}</h2>
    <p>{`When using multiple buttons, the primary button appears to the right and any secondary buttons appear to the left. Research has shown that performance differences between secondary and primary button placement are negligible, however `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/ok-cancel-or-cancel-ok/"
      }}>{`maintaining consistency`}</a>{` throughout a product, offering, or platform is crucial. The Secondary / Primary button order (secondary to the left and primary to the right) is therefore our required guidance and should be followed at all times.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.902173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACGElEQVQoz32SS4/SUBSAu1G30JYWGAyGAAIjnRlUFuz9By4cNT7W7ia68i8QY9ods/a1IgUjhB2BsiPAChKIGUQoAVtwRloH0nu8l4HBiY+bfDmntzffOe09FMdxGZ7ns5iMw+H4J+Tc/yAOEqm1DD9k3W53dmtr6wIej2e573Q6/8Dlci1Zy0ikyAuGYTKBQOATXqqiKJNyuaxh9FKppBeLRb1Sqej1el2v1WrnsVqtao1GY1IoFNRwOJyjaVomrqXQbrfLkUgk1263T6fTKYxGI9A0DYbDIQwGAxiPx2CaJhiGsWQ+n8NisQCy+v3+qSAIeZvNthESO6mCK846nQ60Wi2r2WyibreLVFVFvV4Pffk6QL2+irq9Php905Gmf7eOTwzofD6a3YhG86SpC8JQKJTDnRikqmVZiCycbkAzzAnOfyCwjhEspjg3YDo6MnaEaN62FpIfybKs7PV6c8lk0kilUiCKIpIkCYmihCTxNXolHaKX7zT0/P0cHbwx0cHbn8v44oMFz1ID41pAyLOMXebXQnxDMo65eDxuJhIJiMViK27CrZgA27fvAPVAB+oRALWPub/iIebuxGSu7uR5h03m+I0wQ9r1+Xwlv9+v4Bs/IxhUggG/4r++rTDCvkLvPlHYvccKs7ti76nCCvdKnMvzkefwrJKx+X0o8aen/wpDp530lbTLfintPOfyWcT7axmPHb8AWDGF8twEe0wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary/Primary button order in Modals",
            "title": "Secondary/Primary button order in Modals",
            "src": "/static/206133f5dc9c3edf330024c5fd43028c/fb070/button-usage-4.png",
            "srcSet": ["/static/206133f5dc9c3edf330024c5fd43028c/d6747/button-usage-4.png 288w", "/static/206133f5dc9c3edf330024c5fd43028c/09548/button-usage-4.png 576w", "/static/206133f5dc9c3edf330024c5fd43028c/fb070/button-usage-4.png 1152w", "/static/206133f5dc9c3edf330024c5fd43028c/c3e47/button-usage-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button order in a modal</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "button-alignment"
    }}>{`Button alignment`}</h2>
    <p>{`Button alignment depends on where the buttons appear.`}</p>
    <ul>
      <li parentName="ul">{`Use full-bleed or full-justified buttons for modals and side panels.`}</li>
      <li parentName="ul">{`Use left-justified buttons for forms on their own page (not in a modal or side panel).`}</li>
      <li parentName="ul">{`Use right-justified buttons if the primary response advances the user through a multi-step configuration such as a wizard.`}</li>
    </ul>
    <h3 {...{
      "id": "modal-button-alignment"
    }}>{`Modal button alignment`}</h3>
    <p>{`Single buttons or button groups in modals use full-bleed, full-width buttons. Single buttons span the full width of the modal.`}</p>
    <p>{`Full-width buttons provide significant advantages in time-to-target evaluations using `}<a parentName="p" {...{
        "href": "https://www.interaction-design.org/literature/book/the-glossary-of-human-computer-interaction/fitts-s-law"
      }}>{`Fitts’ Law`}</a>{`, and are increasingly common in other contexts users are likely to encounter.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.44565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB6UlEQVQoz5WSS4/SUBSAG3+A9iF9wGRMNGQCDmg0GX+LCxN1yVbnF7hwM8adv8HEaNIpDpW4IcHQSAQ77kiEDuUxMPYWBlqEgR7vaWiMGl83+XLOPffe79zFYURR3JckScMYi8V+C57/icjBYELBohaPx7VEIvFT3NAURdFkWf4FrCORDCODB4Ig7CeTyQNd1/uGYbjlctmhkEqlQkqlEqlW35ND0yT1ej2kVqshjmmabrFY7KdSqQLP8yq6QiHHcWo6nS40Go15p9MB27bBsqyQz00LesdDcIgL4/EYJpMJLJdLWCwWgKvb7c6z2azOsux3Idqxy0fzk2cd2dC02quW1Q6arXbQ6jhB44gEzfZJMBh+CYYnTkDcceCQ0ep06mNDL5PJ6PipH364vX21cPjhne+PjsEf9YMp6YLn9mDqdnFP6YX7r5MBwPIUYDEOAGZABpZ/LZvR2UgoSbJ2UeDVS5e3Dh6/sL1nb+ewl5+s9vLTkCevo+itWee09lSfw6PntndlK6MLPKdKKBSpUI5xKp9IFZg7szMmB8Dco9z9B+5Tbs/OhM3rb6QYq6KLCunYiHTOlI08eyNnnN/ZrbI7DygP/wK9c2u3yt3MGaKymQ8dODYSDuZaqrDnXikXmJf/BX0TydD1DQGMkAlU9+vmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button in a modal",
            "title": "Primary button in a modal",
            "src": "/static/1ba745271c19a3592560d877856ad185/fb070/button-usage-3.png",
            "srcSet": ["/static/1ba745271c19a3592560d877856ad185/d6747/button-usage-3.png 288w", "/static/1ba745271c19a3592560d877856ad185/09548/button-usage-3.png 576w", "/static/1ba745271c19a3592560d877856ad185/fb070/button-usage-3.png 1152w", "/static/1ba745271c19a3592560d877856ad185/c3e47/button-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Single primary button in a modal</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.902173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACGElEQVQoz32SS4/SUBSAu1G30JYWGAyGAAIjnRlUFuz9By4cNT7W7ia68i8QY9ods/a1IgUjhB2BsiPAChKIGUQoAVtwRloH0nu8l4HBiY+bfDmntzffOe09FMdxGZ7ns5iMw+H4J+Tc/yAOEqm1DD9k3W53dmtr6wIej2e573Q6/8Dlci1Zy0ikyAuGYTKBQOATXqqiKJNyuaxh9FKppBeLRb1Sqej1el2v1WrnsVqtao1GY1IoFNRwOJyjaVomrqXQbrfLkUgk1263T6fTKYxGI9A0DYbDIQwGAxiPx2CaJhiGsWQ+n8NisQCy+v3+qSAIeZvNthESO6mCK846nQ60Wi2r2WyibreLVFVFvV4Pffk6QL2+irq9Php905Gmf7eOTwzofD6a3YhG86SpC8JQKJTDnRikqmVZiCycbkAzzAnOfyCwjhEspjg3YDo6MnaEaN62FpIfybKs7PV6c8lk0kilUiCKIpIkCYmihCTxNXolHaKX7zT0/P0cHbwx0cHbn8v44oMFz1ID41pAyLOMXebXQnxDMo65eDxuJhIJiMViK27CrZgA27fvAPVAB+oRALWPub/iIebuxGSu7uR5h03m+I0wQ9r1+Xwlv9+v4Bs/IxhUggG/4r++rTDCvkLvPlHYvccKs7ti76nCCvdKnMvzkefwrJKx+X0o8aen/wpDp530lbTLfintPOfyWcT7axmPHb8AWDGF8twEe0wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary / Primary button order in a modal",
            "title": "Secondary / Primary button order in a modal",
            "src": "/static/206133f5dc9c3edf330024c5fd43028c/fb070/button-usage-4.png",
            "srcSet": ["/static/206133f5dc9c3edf330024c5fd43028c/d6747/button-usage-4.png 288w", "/static/206133f5dc9c3edf330024c5fd43028c/09548/button-usage-4.png 576w", "/static/206133f5dc9c3edf330024c5fd43028c/fb070/button-usage-4.png 1152w", "/static/206133f5dc9c3edf330024c5fd43028c/c3e47/button-usage-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button pair in a modal</Caption>
      </Column>
    </Row>
    <p>{`If there is a third action, place this within the modal itself as a link, rather than alongside the Secondary / Primary buttons.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.902173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACFUlEQVQoz42Sz4sSYRjHvVTHZpxxxh+FoTuGbo5k5UHw2H/QodiDbXTrGgT5N0jRdpDCcz9B1GjEo8u7nhQFy4ss7pS7kqmz6szoiu/T+xrSLhY08OH78rzDZ55538ciCEJOFMU8TZvNtgat/w8rh4UuCLSYdzqdeZfLdSbtdvs/cTgcS1Yymha6wXFcTpKkz4qiHJXL5SFCqF8qlQZob29Qq9XWqFarlH69Xh8Wi8Ujv9+vWK3WLHUthSzLZgOBgNJqtWaapkGv14NutwuDfh9M0wBdn4Bp0NTBIDmfz5fQp9PpzGRZLjAMc1ZIv1KpVPRmswmNRmPR+PIVt/bb+ED9jvfbKj74dojbZK2S/DnQcH9wvBiNDSDv6NeCwQJ1rHWoqqpBu9C0IT6eTPF4Bng0BTwhiU90DHiMYUGYjwgaBjBA+9E2QnKwwKyE9CB5ns+63W4lmUwa6XQaUqkU3tnZ+c2L5/jZy9c48aaPEx9P8NP3Jk58mOInb038+N0CHr06NK5IcoHn2Ky4EpIbypJUIpGIGYvFIBqNQjgcJtyAm2EZNm/dBsvWACwPASzbhAeEOGGLcGdocpdDBdHGZAXxjzBH2/V4PLterxdRyK0jyedDPmkDbVzdRJx8D12U7yNGji9hQ3HEX99GvHx3V3Bc+iQKZGbp2JweSvLrmb/CWTN264WMgz13ivMZO0laX8lE4vgFefiF76mMxMcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary / Primary button order with a third action in a modals",
            "title": "Secondary / Primary button order with a third action in a modals",
            "src": "/static/c4d1f300fb1e5909a179a270bfa5a2af/fb070/button-usage-5.png",
            "srcSet": ["/static/c4d1f300fb1e5909a179a270bfa5a2af/d6747/button-usage-5.png 288w", "/static/c4d1f300fb1e5909a179a270bfa5a2af/09548/button-usage-5.png 576w", "/static/c4d1f300fb1e5909a179a270bfa5a2af/fb070/button-usage-5.png 1152w", "/static/c4d1f300fb1e5909a179a270bfa5a2af/c3e47/button-usage-5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">
  Secondary / Primary button pair with a tertiary action in a modal
        </Caption>
      </Column>
    </Row>
    <p>{`Use this same layout for buttons in side panels. Use full-width single buttons or full-width Secondary / Primary button pairs. If there is a third action, considering placing it within the side panel itself, rather than as part of the button group.`}</p>
    <p>{`Side panel buttons should be visible without scrolling. They should be visible if they’re at the top, the bottom (without scrolling), or sticky and at the bottom of the view if the content is longer than the viewport.`}</p>
    <h3 {...{
      "id": "non-modal-forms"
    }}>{`Non-modal forms`}</h3>
    <p>{`On non-modal or in-page forms, align single buttons or Secondary / Primary button groups to the left. Buttons should align with the form controls regardless of the user‘s window width.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.95108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADEklEQVQ4y61US0xTQRSdVx4itQX8REhNNCaUjS5cNoFgYkxIXMjCpDGYqGBQxBLdEl2bYKMsGqKycCksTIyBuBJBBCxSNF2AiYX+6P9DKdCWGt4bz0zfK038Ep3k9Nw3M++8M/feKSEYtbW1RGU1Lp3f1aivr+dsMBiKc3q9nhiNRh6bzeYiqyid/2E0NjZybmlpEUwmU11TU5MhnU6L2WxWZLyxsVG+tramoZQKQFk+n1fni1hfX+caiAui2Ej+dRTF4vE4Z1mWK1dWVp5Fo9GRzc3NIWwYBp4Do5lMxoy1S36//y3cDCtrbM8LYADQFUVhX3VZEQgEeiORyMPV1dUHyWTSCvQBA6lU6mwwGDzj9XptiPuUNbbnEXAP0DINMCEWi4ULNjc3C1artYrlCWA50+RyubJQKCQwB5Ik6Vge4bQMJjT4aCm4BucSh7pwOGyHi6DH43HDrQ/F8GGdwavAVwL2HALsQA3TAAtkZmaGCzqdTs3k5OTxiYmJhrGxMePCwsIx5OzoH8D2HAE0TANMCJxwQeSoHGjHEW8DFiS/E8W59ht0AJ1Ix0WgolgUtYfAWlh+CcziqNNIvh05+R0+YM889r7CO1VMA7Hw3/qwqKFWCBNaFGXU5/M5cfRpxO+BKbTRFFIwxWKVlXm2PptIJEbgrFppG4GdW1AEqyAWR1vQ5eVlimpTt9tNUXHObG5paan47HK5KMQo8hhHug4qORTIWnpH0OP1J7z+AHV7fMrLbs5MgDH7GFxRuOMMZxRiUdz7HUG6nVKOnNPSrfiQnIm8k7PRN8lE7GMsFp+PxWIOBlxJB47kwEsOiDDMAU4U5nUkEuZHhmOBVHZ+KySzLSvWdG+fq74lmXVdUuvp+1v7KR3XfPqaKIcz/g+DnhVxm0SbzSb29/eLg4OD4tMnj0XTqRNc4o6lG7/ttFDq81S/7yZ17bmBcl2lCXKZGpX6/VWV2y60FoK91yXODb2SQK5Ih4QOuU7bJR0+eTcvUtpDxj9HBNb8vwKKR+Zm7Vzjy+JiQfRAj/zzz7bLu+7H79qlFaqh+LAiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button alignment in Forms",
            "title": "Primary button alignment in Forms",
            "src": "/static/81c5b501020e2cac80cc76fe8dc87b00/fb070/button-usage-6.png",
            "srcSet": ["/static/81c5b501020e2cac80cc76fe8dc87b00/d6747/button-usage-6.png 288w", "/static/81c5b501020e2cac80cc76fe8dc87b00/09548/button-usage-6.png 576w", "/static/81c5b501020e2cac80cc76fe8dc87b00/fb070/button-usage-6.png 1152w", "/static/81c5b501020e2cac80cc76fe8dc87b00/c3e47/button-usage-6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Single button alignment in forms</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.95108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADQElEQVQ4y61US0xTQRSd15YQPi0kkpTUqDEBF7rQjaRQBOVjG9BootZGNwISDTbBaFyYuHSDjbIgRGTBUliYEANxIyjyE4IsugAXtKUFSh+25dOWn6Udzwx9zyYa0OgkJ/e+mTvnnft5jxAsrVZLJCv5yft/tfLy8rjV6XTynlqtJvn5+dw3m82ylZC8/8syGAzcGo1GQa/X5xYXF+vW1tZUGxsbKmbD4XDK6uqqglIqAMrt7W1pX0YoFOIc8HdJEUj+dclkfr+f23g8njY/P9+xtLTUE4lEOhHQBbwGetfX1804uzk3N/cBaroSZyzmDdAKZMqkkC+pTF1YWHgsiuLz5eXlZ8Fg0AY0Aa0rKysVXq+3zO12t8BvSpyxmBfAEyCdccASYrVaOWFJSYlgs9k0rE4Aq5lic3NTubi4KDAFsVgsk9URSpUQocBLk8E5uE1SmOnz+cagwjs7O+uCWg+a4cE5gzsBTxLY8yIwBmQzDliBjI6OckK73a4YHBw8OjAwcKy/vz9/amrqCGp2eB+wmIOAgnHAEgIlnBA1SgFqkGIjYEXx69Gcuj1QC9SjHBYgVW6KNEOw6ZDcDYwj1REUfww12QufETOJ2Le4o2Ec8IX/Nocyh9QhbKSjKb0ej8eO1EfgDwHDGKNhlGCY+ZJN7LPz8UAg0ANlWYmxEVjeQoJQAzI/xoI6nU6KblOXy0XRcW7ZnsPhkJ9nZmYoyCjq6Ee5DiRqKMh5M0KQBEDKLzgcTjrrnqMOJwi4dVH2MqiiUMct7lKQLeG7/0ko5c5SjkajnVtbW5++R6PvI+HQUDQifoyvi310U+wLLYsjgWBwEpe+gIRhArCjMe9E0cdThmKBlJeXc8KCggKVyWSqqqq+cO18xTlz2eX609mN1JTVEL2acTd2Xfcgdpy9++HTjpSWlhZVc3Ozqr29XfWq7aVKf+oE57hvbSAEvyueMv5/6srKSofRaNo5Y9DTk6WWtjQrnU65vbND6kB1iz7itw5R4XddvnHl0q4jKbRYLEJRUVGOvrBQW1FWmnv2Yq2G1NAcZW1Um3Ynnpt9byeDxXWPfBPYxyABdScT42Oc4+v09C5pdXX1nw1b7f7z+gOAywfv1jClsAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary / Primary button alignment in forms",
            "title": "Secondary / Primary button alignment in forms",
            "src": "/static/686828d00a550c80112ae8603f5b11af/fb070/button-usage-7.png",
            "srcSet": ["/static/686828d00a550c80112ae8603f5b11af/d6747/button-usage-7.png 288w", "/static/686828d00a550c80112ae8603f5b11af/09548/button-usage-7.png 576w", "/static/686828d00a550c80112ae8603f5b11af/fb070/button-usage-7.png 1152w", "/static/686828d00a550c80112ae8603f5b11af/c3e47/button-usage-7.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button alignment in forms</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "multi-step-forms"
    }}>{`Multi-step forms`}</h3>
    <p>{`When the primary action implies a navigation step forward, as in a wizard, align single buttons or Secondary/Primary button groups to the right. This position conveys the “next step” intention.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.80434782608697%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADEElEQVQ4y62UTUhUURTH73ujomg2CjUj5RRWi6BF6xZBuYvAWmQhgZaWCxPHkjbWZItILYlIJdSpjZTpTkJnBBEXLSRSkNRyBr+/mHnzoTPT2Iy+0/++5zOT1D688OOc+/H+nHPPeZexXRoOh0N1xLwIY0TqpJIEdo90LDcUw/KijLkxbyMx7ibpiEgEAtAFg0Hdmi8ODQ3FTE5OKp87nU7GEgpXVbGXJOizP6QZslr2mSooIb5QPpp6rvmI8XT5gfTSb8m3yyuN5pLiQ62tbak2m01vNptNNTU1Bo/HkzQ4OChyibGxsY1B03+l7PV6VcfW0a7YrvZmobOzI+PLV4dJJjLSStBAsmz4HiXjYiCUFo1GjUjREA6HjRzuRyIRI9I3hkKhWK4By9ji4qIaH1HCwsLCwNTUVBC44XuXlpa82N8KD/ABFzjJNWBF1tTUpAharVbW2NiYXF9fr7dYLCktLS17+vr6Eru7u5N6enp+R+IGq9whLGNahWBjJyYmihHdA/j33W53GSI0I6XSLdD2SsB+rgErMHykpRwnSdKr+fn5bpfL1YGU7RDt8vl8XbhwxW7CvmY7wTGuASvsVl+rBVlT1SKMn52dfT83N/cZUX/CBffvAD8zAD6C4+tFQZqaoA5i5yGag2a9jPUrO6CdyQYpXEPRstvtimBDQ4PQ29t7WJZl3nO8x/4IfhbEcA3M2caiJKAg/ShGCGtaj20H70U/rszt9/t/9uGmofvXopD2wODetIXY8fHxCtzhc1TscSAQqEJfVXICa9a/FKz2+oNPN+LxB6vBQa4BKzCEu96HMzMzz6anp9uQxhv03lsNH5A83nerYY+VZKmWVqU6UAu/jkh6AUyqhiT8Gu5OI5POsqtUzXLIAlvBLtFDlgX/IqUp+xfwfi4vL6+LjoyMiMPDwyKqJmJ9nRuvV+L4GbyRFn2RLO0tkkdTi2Un5mNCvjyqK5BPKI91viwyVFUR5HYr+Mi4u7r9b3XtL95TU5l6WH+LhDNVJJ56RELmExLS7yDFXBJiCmT14HViPwC+zR/YpEWHWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button aligment in a wizard",
            "title": "Primary button aligment in a wizard",
            "src": "/static/2d1c0ba78b57b4deef85bd3f95facc80/fb070/button-usage-8.png",
            "srcSet": ["/static/2d1c0ba78b57b4deef85bd3f95facc80/d6747/button-usage-8.png 288w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/09548/button-usage-8.png 576w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/fb070/button-usage-8.png 1152w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/c3e47/button-usage-8.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Single button aligment in a wizard</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.94021739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADVUlEQVQ4y62UX0hTYRjGv2+bqRDaVu0opVHp0AryRrowHUSIQaUXFVL2RyvElVhdrVV2E5ldpCSRWRHVTcHKNNRRsDACgzI1kLSZ+X9ubu7M1JXbztdzzjbLoJLywI/nPd/Z9+x9z/uej5AFuiwWSyCgh3yEMBa4ucAoOc3k5OC0guT7KWnH/T0miyhkCsaYDNAZr08+OTkpF2Mg7+zsVAwMDEjbe3p6CIks9AfMrjOq3PMqlst+uDzuPIuMKBQSVNvuruEyzqyIPzkddcpQFnOipHiV0fhI2djYuKSkpCS+oqJC7XQ6F7e3t8tEi97e3oBXKMH/ucbHxwNBw9Mnkj55UENNTU2rLT2f41AKx5hHzQRB/c3H1O4v05zX68MaU3s8XzmPxyPFMzMzHMrnpqamFKIHlBCedwezZJFWq7UV74MH1tHRUfvExITd7Xb/DhsYA8NgvegBlZH6+nrJ0GQy0dra2mVGo5GrqamJMZvNS7u6upRtbW2qjo6OP6EEctEDSkhfX59k2N/fH4ZYBzUAvd1uP4EMj6Ok4t8QeqYDy0QPKCXYFCo53OFw3EepL8fGxp5DzdAXLpfL/AfE58/AWtEDShdqrsXs5rYbGUYMDw83jIyMvEfWb/CCW//CW/AOvAaJwaZQgsEMGcphuHNoaCgPa3tR/nzYB3LBEtEDSgimXjKsqqqizc3Nq2Ac4/V61fPF7/eLKs0hlPzcFGkOAZYmxPly/AXxN040wsrz/I85TE5Onn2xaWlp4SqVSvFf3UETQhmG4eMuxTu8ggzL8M+Xf4bnXeW8+8ulcX7ycpDyoJaBWNHDyWMOkW7IcBGacnVwcPCxz+e7jftqcCOIGN9iX113mN9RzXyOG6CaCVDmuAZdGfBw0JDZnKwTExN3paamlqWkpJzTaDSlmqR1FzRxUYVki3UH2c8ukr3sHMljpWQ3O0+y2VmSwzhpYw7OT5wcUqzX60ldXZ30TWZmZt7MysoahXZrtdqPGdotQ1s3bzRFFdgro48za/QxoVtZLFhwZn6SHRY+yI8ISeI+xDJis9nmZNfS0vJvzcj/5VBNT0+fLb2oqIgYDAaq0+ko1unmdC3N2LSBrjhmI3uqGd1eKdBcaIIeJR5gNOxo0KyAke/v5xZsT9Vp6QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary / Primary button alignment in a wizard",
            "title": "Secondary / Primary button alignment in a wizard",
            "src": "/static/1c53041897d5028a44c6e435149eaf57/fb070/button-usage-9.png",
            "srcSet": ["/static/1c53041897d5028a44c6e435149eaf57/d6747/button-usage-9.png 288w", "/static/1c53041897d5028a44c6e435149eaf57/09548/button-usage-9.png 576w", "/static/1c53041897d5028a44c6e435149eaf57/fb070/button-usage-9.png 1152w", "/static/1c53041897d5028a44c6e435149eaf57/c3e47/button-usage-9.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button pair alignment in a wizard</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "labels"
    }}>{`Labels`}</h2>
    <p>{`Button labels should clearly indicate the action of the button. Use active verbs, such as `}<strong parentName="p">{`Add`}</strong>{` or `}<strong parentName="p">{`Delete`}</strong>{`. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) and no more than three words for button labels.`}</p>
    <p>{`For sets of buttons, use specific labels, such as `}<strong parentName="p">{`Save`}</strong>{` or `}<strong parentName="p">{`Discard`}</strong>{`, instead of using `}<strong parentName="p">{`OK`}</strong>{` and `}<strong parentName="p">{`Cancel`}</strong>{`. This is particularly helpful when the user is confirming an action.`}</p>
    <p>{`For consistency, see `}<a parentName="p" {...{
        "href": "/guidelines/content/glossary"
      }}>{`labels and idioms`}</a>{` for the approved list of action labels.`}</p>
    <h2 {...{
      "id": "icon-usage"
    }}>{`Icon usage`}</h2>
    <ul>
      <li parentName="ul">{`Use glyphs (16px) within buttons.`}</li>
      <li parentName="ul">{`Glyphs are distinguished by their solid shape and knocked-out details.`}</li>
      <li parentName="ul">{`Glyphs should always appear to the right of the text.`}</li>
      <li parentName="ul">{`Glyphs used in buttons must be directly related to the action that the user is taking.`}</li>
      <li parentName="ul">{`Glyphs must be the same color value as the text within a button.`}</li>
      <li parentName="ul">{`Ghost buttons require a glyph icon.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAz0lEQVQY03WRSwrCMBCGM2nRWi0oVHDl1oUinsJbiPbhA4WC4Mo7eAuvoIgKerL8TtpUWrEDHxn+SeaREe4GQhtFEL09qH8AsUZWDLJjJSotRJlcy5zqh5ILiUCjiE+Z+pH6X0Cb7sQIQ2eNG3d3Ze1CIe7M2BRsc7zJNJiWuS8Zh6mVOrTzhAFG3havboJnfYUHf8FbRmpiLvpMh3EZz2hWmvw3YWpzVI5sLwvj6XEXhT/7N7KfGGcGMT2BdmfQ4JgthjvOYjEyqpYSqK/2AWO4bQCyumTQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "button with glyph",
            "title": "button with glyph",
            "src": "/static/d08b0e22f8e12982853e2afff8e5c535/fb070/button-usage-1.png",
            "srcSet": ["/static/d08b0e22f8e12982853e2afff8e5c535/d6747/button-usage-1.png 288w", "/static/d08b0e22f8e12982853e2afff8e5c535/09548/button-usage-1.png 576w", "/static/d08b0e22f8e12982853e2afff8e5c535/fb070/button-usage-1.png 1152w", "/static/d08b0e22f8e12982853e2afff8e5c535/c3e47/button-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Glyph usage in buttons</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "danger-button-usage"
    }}>{`Danger button usage`}</h2>
    <p>{`Danger buttons have a different visual style to inform users of potentially destructive actions they are about to take. If using the danger button as a standalone, we recommend styling it as a `}<strong parentName="p">{`secondary button`}</strong>{`. Within a set, the danger button should be styled as a `}<strong parentName="p">{`primary button.`}</strong></p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB0ElEQVQoz6WSzWoaURSAB3ThQpCqM44aYgPZjBSULootTbZ5D1/BjfgKXde3iMaaEkpQUZQoUrPOwp84Y0fJXOcnk4Vp5p6eax1r29BNBz7uOWcO3zD3HE4QhE+RSKTKzv/BdXC7iSiKW7D2V87z/JZnpFXWw8VisarP5ytns9mrTqezbDabd61WS3PBXGs0Glq73db6/f6WXq+ndbvdu8FgoBeLxZtAIFDBD1e5eDx+7vF4SoVC4XqxWMBwOITxeAyTyQSm0ymMRqM1iizDvWWBaRjwYNvgPD3B42oF7KnXanO/338WjUZ/CfP5/FcDmxVFcWazGZVlmRJCqG3b1LIsujQMSkyDarpOl6ZJTazrluU4KPxyeTljQtEVer3eUi6XG6AAUOaoqkr/REfMb8h8Tk11Tg3Ml9j73bTgolL5KWS/jBd5jpdcSiQS15lMBlKpFEiSBMlkco2EvMK8/uYYyNEJTNNvQUm/W3OLsf76PZxKadXP82ciDpfbTKeM0itED4VCWjAYJC4vkDDyMZog9b1D8ll8SS42YKzVxAPjg7h/ExCEylrIRu2O3GV3XVx4rIfZ6rB4h/DmXQQdAlub54Sb3fztZM2CG/9jsX8AEyZ3i3gzfoEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "danger button set in modal",
            "title": "danger button set in modal",
            "src": "/static/68954cf3f3a42c833af2376d2241348e/fb070/button-usage-2.png",
            "srcSet": ["/static/68954cf3f3a42c833af2376d2241348e/d6747/button-usage-2.png 288w", "/static/68954cf3f3a42c833af2376d2241348e/09548/button-usage-2.png 576w", "/static/68954cf3f3a42c833af2376d2241348e/fb070/button-usage-2.png 1152w", "/static/68954cf3f3a42c833af2376d2241348e/c3e47/button-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Danger button set</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Button type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Primary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For the principal call to action on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Secondary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For secondary actions on each page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Button with icon`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When words are not enough, icons can be used in buttons to better communicate what the button does. Icons are always paired with text.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Disabled button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when the user cannot proceed until an input is collected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Set of buttons`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action required by the user has more than one option, always use a a negative action button (secondary) paired with a positive action button (primary) in that order. Negative action buttons will be on the left; positive action buttons on the right. When these two types of buttons are paired in the correct order, they will automatically space themselves apart.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Small button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when there is not enough vertical space for a regular sized button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Ghost button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action does not require primary dominance on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Danger button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action has potentially destructive effects on the user‘s data (delete, remove, etc).`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <ul>
      <li parentName="ul">{`Jakob Nielsen, `}<a parentName="li" {...{
          "href": "https://www.nngroup.com/articles/ok-cancel-or-cancel-ok/"
        }}><em parentName="a">{`OK-Cancel or Cancel-OK? The Trouble With Buttons`}</em></a>{` (2008)`}</li>
      <li parentName="ul">{`The Interaction Design Foundation, `}<a parentName="li" {...{
          "href": "https://www.interaction-design.org/literature/book/the-glossary-of-human-computer-interaction/fitts-s-law"
        }}><em parentName="a">{`The Glossary of Human Computer Interaction, Chapter 37`}</em></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      